import Vue from "vue";
import Alertize from "@/components/Alertize.vue";
import Buttons from "@/views/Admin/Commons/Buttons.vue";
import { getDefaultSortingOption } from "@/utils/filter-global";
import { Paginated, SortingOption } from "@/interfaces/paginated";
import GridTable from "@/components/Header/Tables/GridTable.vue";
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue";
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue";
import ActiveFieldTable from "@/components/Header/Tables/ActiveFieldTable.vue";
import Archived from "@/components/Header/Tables/Archived/index.vue";
import CustomizeRowGrid from "@/components/Header/Tables/CustomizeRowGrid.vue";
import CustomizeRowObjectGrid from "@/components/Header/Tables/CustomizeRowObjectGrid.vue";
import { TypeLoading } from "@/interfaces/loading";
import {
	prepareSelectedData,
	prepareTableContent,
	prepareTableData,
	preparedColumnDefsGrid,
} from "@/utils/CustomizeViewData";
import ParamService from "@/services/params-service";
import { mapActions, mapGetters } from "vuex";
import omit from "lodash/omit";
import { PaginatedEntity } from "@/models/Paginated";
import { isEmpty, isUndefined } from "lodash";
import { EventArchived, EventUpdate } from "@/interfaces/event";
import { isNull } from "lodash";
import { getPermisionEvent } from "@/utils/permissionResolve";
import NotPermission from "@/views/Errors/not_permission.vue";

export default Vue.extend({
	name: "EventIndex",
	props: {},
	components: {
		Buttons,
		Alertize,
		GridTable,
		ActionsTable,
		FilterGridAG,
		ActiveFieldTable,
		Archived,
		CustomizeRowGrid,
		CustomizeRowObjectGrid,
		NotPermission
	},
	data: () => ({
		paginated: { page: 1, limit: 25 } as Paginated,
		filters: {},
		options: getDefaultSortingOption("external_id"),
		table: {
			headers: [] as any[],
			items: [] as any[],
		},
		fields: {},
		columnRestored: false,
		context: null,
		selectionRows: "single",
	}),
	created() {
		this.$nextTick(async () => {});
	},
	mounted() {
		this.$nextTick(async () => {
			await this.saveFilters();
			await this.verifyFilters();
			await this.getPaginated();
			this.context = { componentParent: this };
		});
	},
	computed: {
		...mapGetters("customize_view", [
			"getFieldByView",
			"getSelectedByView",
		]),
		...mapGetters("events", ["storePaginated"]),
		...mapGetters("internationalization", ["getLanguage"]),
		...mapGetters("customizer_filter", ["getFiltersEntity"]),
		...mapGetters("profile", ["getAbility"]),

		config() {
			return {
				paginated: this.paginated,
				filters: this.filters,
				options: this.options,
				fields: this.fields,
			};
		},
		async getConfig() {
			return await ParamService.getParams(
				this.paginated,
				this.filters,
				this.options,
				this.fields
			);
		},
		getResultPaginate(): PaginatedEntity {
			return this.storePaginated;
		},
		getData(): any[] {
			const result: PaginatedEntity = this.getResultPaginate;
			return result.data || [];
		},
		gerRowsData() {
			return this.table.items || [];
		},
		getConfigColumnDef() {
			return {
				context: this.context,
				elegible: true,
				eventActive: false,
				showSwitch: true,
				entityName: "Event",
				redirect: "EventEdit",
				checkDisableEdit: true,
				edit: true,
				archived: true,
				flex: 0,
				headerCheckboxSelection: false,
				checkboxSelection: false,
				minWidthActions: 170,
				maxWidthActions: 170,
				redirecCustom: { to: "EventEdit", isColumn: true, parameter: "params", isInvoke: false },
				permission: this.getPermission
			};
		},
		getColumnsDef() {
			return preparedColumnDefsGrid(
				this.table.headers,
				this.getConfigColumnDef
			);
		},
		getFiltrosAplicados() {
			const newFilters = this.getFiltersEntity("EventIndex").filters;
			return Object.keys(newFilters).length > 0 ? newFilters : {};
		},
		getOptionsAplicados() {
			return this.getFiltersEntity("EventIndex").options;
		},
		getSize() {
			return this.$t("show.events", {
				total: this.getData.length,
			});
		},
		canList(){
			return this.getAbility.can(this.getPermission.actions.index, this.getPermission.subject);
		},
		getPermission(){
			return getPermisionEvent();
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),

		...mapActions("events", {
			fetchPaginated: "paginated",
			setArchived: "archive",
		}),

		/** Begin Customize View **/

		...mapActions("customize_view", [
			"setFields",
			"saveSelected",
			"saveColumns",
			"getTableDataSelected",
		]),

		...mapActions("customizer_filter", [
			"saveFiltersEntity",
			"resetFilterEntity",
		]),

		async updateItemsTable() {
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);

			this.fields = await prepareSelectedData(selected);

			this.table.items = await prepareTableContent(
				this.fields,
				this.getData
			);
			this.$forceUpdate();
		},

		async getTableData(
			event: { view: string; isReset: Boolean } = {
				view: "",
				isReset: true,
			}
		) {
			const { selected, fields } = await this.getTableDataSelected({
				route: this.$route?.name,
				entity: this.getData,
			});

			this.fields = fields;

			const headers: Array<any> = selected.columns;
			const columns: Array<any> = await this.getColumns();

			this.table = await prepareTableData({
				columns: columns,
				fields: this.fields,
				entity: "events",
				entities: this.getData,
				isReset: event?.isReset,
			});

			if (isEmpty(headers) || event?.isReset) {
				await this.saveColumns({ event, columns: this.table.headers });
				//this.columnRestored = event?.isReset
			}

			this.$forceUpdate();
		},

		async getColumns() {
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);
			const columns: Array<any> = selected.columns;
			return columns ?? [];
		},

		async verifyFilters() {
			const newFilters = this.getFiltrosAplicados;
			if (Object.keys(newFilters).length < 1) return;
			this.filters = newFilters;
			this.options = this.getOptionsAplicados;
		},

		async handleSave(event: any) {
			await this.saveSelected(event);
			await this.saveColumns({ event, columns: this.table.headers });
			await this.getTableData(event);
		},

		async handleArchived(event: any) {
			const { id, archived, rowIndex }: EventArchived = event;

			try {
				await this.setLoadingData(
					archived ? TypeLoading.enable : TypeLoading.disable
				);
				
				/**
				 * Envio de datos para archivar/desarchivar evento
				 */
				const response: EventUpdate = await this.setArchived({
					id,
					archived: !archived,
				});

				this.updateArchivedEvent({
					hasError: false,
					id,
					archived: !response.active,
					rowIndex,
				});

				await this.setLoadingData();
			} catch (error) {
				this.updateArchivedEvent({
					hasError: true,
					id,
					archived,
					rowIndex,
				});
				await this.setLoadingData();
			}
		},

		updateArchivedEvent(eventToUpdate: EventArchived) {
			const { hasError, id, archived, rowIndex } = eventToUpdate;

			if (hasError) {
				return this.$refs.Grid_Event.updateRowNodeByIndex(
					rowIndex,
					archived
				);
			}

			const indexToUpdate: number = this.table.items.findIndex(
				(item: EventArchived) => item.id === id
			);

			if (indexToUpdate !== -1) {
				const eventToUpdate = this.table.items[indexToUpdate];
				this.updateFields(eventToUpdate, archived);

				return this.$refs.Grid_Event.updateRowNodeByIndex(
					rowIndex,
					rowIndex,
					!archived
				);
			}
		},

		updateFields(item: EventArchived, archived: boolean) {
			this.$set(item, "archived", archived);
			this.$set(item, "active", !archived);
		},

		/** End Customize View **/

		async getPaginated(uTable: boolean = true) {
			if(this.canList){
				await this.setLoadingData(TypeLoading.loading);
				await this.fetchPaginated(await this.getConfig);
				if (uTable) {
					await this.getTableData({ view: this.$route?.name });
				} else {
					await this.updateItemsTable();
				}
				await this.setLoadingData();
			}
		},

		updatePaginate(data: any) {
			this.paginated.page = data;
		},

		async setFilter(params: { key: string | number; value: any }) {
			this.filters = {};
			this.filters[params.key] = !isUndefined(params.value)
				? params.value
				: "";
			await this.saveFilters();
		},

		async handleUpdateColumns(event: any) {
			await this.saveSelected(event);
		},

		async handleUpdateSizeColumns(params: any) {
			params.forEach((element) => {
				let col = this.table.headers.filter(
					(c) => c.value == element.col
				);
				col[0].reactive_width = element.actualWidth;
				col[0].width = String(element.actualWidth) + "px";
			});
			let event = {
				view: params.entity,
			};
			await this.saveColumns({ event, columns: this.table.headers });
		},

		async selectedOption(params: { options: SortingOption; filter: any }) {
			await this.setFilter({
				key: params.options.sort,
				value: params.filter,
			});
			this.updatePaginate(1);
			await this.updateParams(
				{
					filters: this.filters,
					options: params.options,
				},
				false
			);
		},

		async selectedLimit(limit: number) {
			this.paginated.limit = limit;
			this.updatePaginate(1);
			await this.getPaginated(false);
		},
		async removeKeyFilter(key: string) {
			const newFilters: any = omit(this.filters, [key]);
			await this.updateParams(
				{
					filters: newFilters,
					options: this.options,
				},
				false
			);
			await this.saveFilters();
		},

		async updateParams(
			params: {
				filters: any;
				options: SortingOption;
			},
			uTable: boolean = true
		) {
			this.filters = params.filters;
			this.options = params.options;
			this.updatePaginate(1);
			await this.getPaginated(uTable);
		},

		removeOption() {
			this.filters = {};
			this.options = getDefaultSortingOption("id");
			this.resetFilter();
			this.updatePaginate(1);
			this.getPaginated(false);
		},
		async saveFilters() {
			this.saveFiltersEntity({
				view: this.$route?.name,
				filters: { filters: this.filters, options: this.options },
			});
		},
		async resetFilter() {
			this.resetFilterEntity({
				view: this.$route?.name,
				filters: { filters: {}, options: {} },
			});
		},
		async handleResetColumns() {
			await this.getTableData({ view: this.$route?.name, isReset: true });
		},
		async handleRedirectMethod(params: any){
			if(params.column == "advertiser_name"){
				let event = this.getData.find((i) => i.id == params.id);
				if(!isNull(event)){
					let obj = {
						name: "AdvertiserEdit",
						params: {
							id: event.advertiser_id
						}
					}
					const routeData = this.$router.resolve(obj);
            		window.open(routeData.href, '_blank');
				}
			}
			if(params.column == "segment_name"){
				let event = this.getData.find((i) => i.id == params.id);
				if(!isNull(event) && !isNull(event.segment_id)){
					let obj = {
						name: "SegmentsEdit",
						params: {
							id: event.segment_id
						}
					}
					const routeData = this.$router.resolve(obj);
            		window.open(routeData.href, '_blank');
				}
			}
		},
	},
	watch: {
		"paginated.page"(val, old) {
			if (val !== old) {
				this.getPaginated(false);
			}
		},
		async getLanguage(lang) {
			await this.handleResetColumns();
			this.$forceUpdate();
		},
	},
});
